import React from 'react';
import Link from './Link';

const SiteFooter = ({ footerMenu }) => (
  <div className="site-footer">
    <div className="container">
      <div className="footer-wrapper">
        <div className="copyright">
          &copy; {(new Date()).getFullYear()}. Digital Caravan Factory
        </div>

        <nav>
          {footerMenu.items.map((item, idx) =>
            <Link key={idx} {...item.link} />
          )}
        </nav>

        <div className="credits">
          launched by <a href="http://raketadesign.com" target="_blank">raketa</a>
        </div>
      </div>
    </div>
  </div>
);

export default SiteFooter;
