import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Card = ({ title, link, image, description }) => (
  <div className="article-item">
    {link ? (
      <a href={link} className="article-image">
        <Img src={image} variant="card_horizontal" />
      </a>
    ) : (
      <Img src={image} variant="card_horizontal" className="image" />
    )}

    <div className="article-content">
      <h4 className="article-title">
        {link ? <a href={link}>{title}</a> : title}
      </h4>
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  </div>
);

const cardsVariant = {
  2: 6,
  3: 4,
  4: 3,
};

const CardsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="article-list">
      <div className="container">
        <div className="row">
          {list.map((card) => (
            <div key={card.id} className={`col-${cardsVariant[variant]}`}>
              <Card
                title={card.title}
                link={card.link}
                image={card.image}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CardsWidget.title = "Cards";
CardsWidget.category = "Media";

CardsWidget.propTypes = {
  variant: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CardsWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "Card title",
      link: "#",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 2,
      title: "Card title",
      link: "",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 3,
      title: "Card title",
      link: "#",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/400x300",
    },
  ],
  containerSettings: {},
};

const CardsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

CardsListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

CardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <CardsListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default CardsWidget;
