import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import TableBuilder from '../admin/TableBuilder';

const TableWidget = ({ table, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="table-widget">
        <div className="container">
          <table>
            <thead>
              <tr>
                {table[0].map((col, cidx) => (
                  <th key={cidx}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {table.slice(1).map((row, ridx) => (
                <tr key={ridx}>
                  {row.map((col, cidx) => (
                    <td key={cidx}>{col}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
  </Container>
);

TableWidget.title = 'Table';
TableWidget.category = 'General';
TableWidget.dialogSize = 'large';

TableWidget.propTypes = {
  table: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

TableWidget.defaults = {
  table: [['Col 1', 'Col 2', 'Col 3']],
  containerSettings: {},
};

TableWidget.adminFields = (_, onChange, settings) => (
  <div>
    <TableBuilder
      label="Variant"
      value={settings.table}
      onChange={value => onChange('table', value)}
    />
  </div>
);

export default TableWidget;
