import React from 'react';

const Modal = ({ isOpen, onClose, children }) => (
  <div className={`modal ${isOpen ? 'open' : ''}`}>
    <div className="overlay" onClick={onClose} />

    <div className="content">
      <div className="modal-header">
        <button type="button" onClick={onClose}>&times;</button>
      </div>

      {children}
    </div>
  </div>
);

export default Modal;
