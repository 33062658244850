import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const FetchButton = ({ url, onDataFetch }) => {
  const onClick = () => {
    if (url === '') {
      alert('Fill in the url field');
      return;
    }

    fetch(`/admin/articles/parse_url/${btoa(url)}`)
      .then(response => response.json())
      .then(data => onDataFetch(data));
  }

  return (
    <a
      onClick={onClick}
      className="btn-text"
      href="javascript:;"
    >Fetch</a>
  );
};

FetchButton.propTypes = {
  url: PropTypes.string.isRequired,
  onDataFetch: PropTypes.func.isRequired,
};

const UrlInput = ({ value, namespace, onChange, onDataFetch }) => (
  <>
    <TextInput
      name="url"
      namespace={namespace}
      label="Url"
      value={value}
      onChange={onChange}
    />

    <FetchButton url={value} onDataFetch={onDataFetch} />
  </>
);

UrlInput.propTypes = {
  value: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UrlInput;
