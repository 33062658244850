import React from 'react';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

const Layout = ({ children, ...props }) => (
  <React.Fragment>
    <SiteHeader {...props} />

    {children}

    <SiteFooter {...props} />
  </React.Fragment>
);

export default Layout;
