import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';
import HiddenInput from './HiddenInput';
import UrlInput from  './UrlInput';

const ArticleForm = ({ namespace, article }) => {
  const [formState, setFormState] = useState({
    url: article.url || '',
    articleImageUrl: article.articleImageUrl || '',
    slug: article.slug || '',
    name: article.name || '',
    content: article.content || '',
    length: article.length || 0,
    endPosition: article.endPosition || {},
  });

  const managetFormState = newValue => setFormState((prevState) => ({ ...prevState, ...newValue }));

  const onDataFetch = ({ errors, text }) => {
    if (errors !== null) {
      console.log('errors', errors);
      return ;
    }

    managetFormState({
      name: text.name,
      content: text.content,
      length: text.length,
      endPosition: JSON.stringify(text.endPosition),
    });
  }

  const {
    slug,
    articleImageUrl,
    url,
    name,
    content,
    length,
    endPosition,
  } = formState;

  return (
    <>
      <UrlInput
        namespace={namespace}
        value={url}
        onChange={(newUrl) => managetFormState({ url: newUrl })}
        onDataFetch={onDataFetch}
      />

      <TextInput
        namespace={namespace}
        name="article_image_url"
        label="Article Image Url"
        value={articleImageUrl}
        onChange={(newArticleImageUrl) => managetFormState({ articleImageUrl: newArticleImageUrl })}
      />

      <TextInput
        namespace={namespace}
        name="name"
        label="Name"
        value={name}
        onChange={(newName) => managetFormState({ name: newName })}
      />

      <TextInput
        namespace={namespace}
        name="slug"
        label="Slug"
        value={slug}
        onChange={(newSlug) => managetFormState({ slug: newSlug })}
      />

      <TextAreaInput
        namespace={namespace}
        name="content"
        label="Content"
        value={content}
        onChange={(newContent) => managetFormState({ content: newContent })}
      />

      <HiddenInput
        namespace={namespace}
        name="length"
        value={length}
      />

      <HiddenInput
        namespace={namespace}
        name="end_position"
        value={endPosition}
      />
    </>
  );
};

ArticleForm.defaultProps = {
  article: {
    url: '',
    slug: '',
    name: '',
    articleImageUrl: '',
    content: '',
    length: 0,
    endPosition: {},
  },
};

ArticleForm.propTypes = {
  namespace: PropTypes.string.isRequired,
  article: PropTypes.shape({
    url: PropTypes.string,
    articleImageUrl: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    length: PropTypes.number,
    endPosition: PropTypes.object,
  }),
};

export default ArticleForm;

