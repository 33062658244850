import React from "react";
import {
  Container,
  List,
  TextInput,
  LinkSettings,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Link from "../frontend/Link";

const MediaList = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="media-list">
      <div className="container">
        {list.map((item, idx) => (
          <div key={item.id} className="media-list-item">
            <div className="row">
              <div className="col-4" key={idx}>
                <Img src={item.icon} variant="original" />
              </div>

              <div className="col-8">
                <div className="content">
                  <h4>{item.title}</h4>
                  <div className="meta">
                    <Link {...item.link} />
                  </div>

                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Container>
);

MediaList.title = "Media List";
MediaList.category = "Media";
MediaList.dialogSize = "large";

MediaList.defaults = {
  list: [
    {
      id: 1,
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: "http://placehold.it/400x300",
    },
    {
      id: 2,
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: "http://placehold.it/400x300",
    },
    {
      id: 3,
      title: "Title",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: "http://placehold.it/400x300",
    },
  ],
  containerSettings: {},
};

const MediaListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

MediaList.adminFields = (items, onChange, settings) => (
  <React.Fragment>
    <List
      listItem={(settings, onChangeItem) => (
        <MediaListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </React.Fragment>
);

export default MediaList;
