import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  Img,
  TextInput,
  SelectMenu,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

class SliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, variant, autoplay, list } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="slider-wrapper">
          <div className={variant}>
            <div className="slider">
              <Swiper
                ref={(node) => {
                  if (node) this.swiper = node.swiper;
                }}
                rebuildOnUpdate
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
                loop
                keyboard={true}
                paginationClickable
                slidesPerView={1}
                speed={500}
                grabCursor={true}
                autoplay={
                  autoplay > 0
                    ? { delay: autoplay, disableOnInteraction: true }
                    : false
                }
              >
                {list.map((item, idx) => (
                  <div className="slide" key={idx}>
                    <Img
                      src={item.image}
                      variant="fullscreen"
                      className="slider-image"
                    />
                  </div>
                ))}
              </Swiper>

              <button
                onClick={() => this.goNext()}
                className="swiper-button-next icon-arrow-right"
              />
              <button
                onClick={() => this.goPrev()}
                className="swiper-button-prev icon-arrow-left"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

SliderWidget.title = "Slider";
SliderWidget.category = "Media";
SliderWidget.dialogSize = "large";

SliderWidget.defaultProps = {
  list: [],
};

SliderWidget.defaults = {
  list: [
    { id: 1, image: "http://placehold.it/1920x1080" },
    { id: 2, image: "http://placehold.it/1920x1080" },
    { id: 3, image: "http://placehold.it/1920x1080" },
  ],
  autoplay: 0,
  variant: "container",
  containerSettings: {},
};

const GalleryItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

GalleryItem.defaultProps = {
  settings: {},
};

SliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["container", "Contained"],
        ["full-width", "Full width"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <TextInput
      label="Autoplay delay in ms (0 = disable)"
      onChange={(value) => onChange("autoplay", value)}
      value={settings.autoplay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <GalleryItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default SliderWidget;
