import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const Stat = ({ label, value, icon, icons }) => (
  <div className="stat">
    {icons === "no" ? null : (
      <div className="icon">
        <Img src={icon} variant="original" />
      </div>
    )}

    <div className="content">
      <h6>{label}</h6>
      <div className="value">{value}</div>
    </div>
  </div>
);

const StatsWidget = ({ list, containerSettings, variant, icons }) => (
  <Container settings={containerSettings}>
    <div className={`stats ${variant === "col-2" ? "centered" : "inline"}`}>
      <div className="container">
        <div className="row">
          {list.map((item, idx) => (
            <div className={variant} key={idx}>
              <Stat
                label={item.label}
                value={item.value}
                icon={item.icon}
                icons={icons}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

StatsWidget.title = "Stats";
StatsWidget.category = "Media";

StatsWidget.propTypes = {
  icons: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

StatsWidget.defaults = {
  variant: "col-2",
  icons: "yes",
  list: [
    {
      id: 1,
      label: "Statistics",
      value: "500+",
      icon: "http://placehold.it/600x800",
    },
    {
      id: 2,
      label: "Statistics",
      value: "500+",
      icon: "http://placehold.it/600x800",
    },
    {
      id: 3,
      label: "Statistics",
      value: "500+",
      icon: "http://placehold.it/600x800",
    },
  ],
  containerSettings: {},
};

const StatsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Label"
      onChange={(value) => onChangeItem("label", value)}
      value={settings.label}
    />

    <TextInput
      label="Value"
      onChange={(value) => onChangeItem("value", value)}
      value={settings.value}
    />
  </div>
);

StatsListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

StatsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["col-2", "Six"],
        ["col-3", "Four"],
        ["col-4", "Three"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <SelectMenu
      label="Icons"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.icons}
      onChange={(value) => onChange("icons", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <StatsListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default StatsWidget;
