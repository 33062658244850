import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  namespace,
  name,
  label,
  value,
  onChange,
}) => (
  <div className={`form-group string ${namespace}_${name}`}>
    <label
      className="control-label"
      htmlFor={`${namespace}_${name}`}
    >{label}</label>

    <input
      value={value}
      onChange={e => onChange(e.target.value)}
      className="form-control"
      type="text"
      name={`${namespace}[${name}]`}
      id={`${namespace}_${name}`}
    />
  </div>
);

TextInput.propTypes = {
  namespace: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
