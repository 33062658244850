import React from "react";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const AppScreen = ({ image }) => (
  <div className="image-wrapper">
    <Img src={image} variant="original" className="logo" />
  </div>
);

const AppDescription = ({
  title,
  description,
  app_store_link,
  play_store_link,
}) => (
  <div className="content-wrapper">
    <div>
      <h1>{title}</h1>
      {description != "<p><br></p>" && (
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      <div className="button-wrapper">
        <a
          href={app_store_link}
          target="_blank"
          className="store-badge app-store"
        >
          Download on the App Store
        </a>
        <a
          href={play_store_link}
          target="_blank"
          className="store-badge play-store"
        >
          Download on the Play Store
        </a>
      </div>
    </div>
  </div>
);

const AppHeroWidget = ({
  variant,
  background,
  image,
  title,
  description,
  app_store_link,
  play_store_link,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="app-hero">
      {background && (
        <div className="background">
          <Img src={background} variant="lead" />
        </div>
      )}

      <div className="foreground">
        <div className="container">
          <div className="row">
            {variant === "image-left" && (
              <div className="col-6">
                <AppScreen image={image} />
              </div>
            )}

            <div className="col-6">
              <AppDescription
                title={title}
                description={description}
                app_store_link={app_store_link}
                play_store_link={play_store_link}
              />
            </div>

            {variant === "image-right" && (
              <div className="col-6">
                <AppScreen image={image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

AppHeroWidget.title = "App Hero";
AppHeroWidget.category = "Media";

AppHeroWidget.defaults = {
  variant: "image-left",
  background: imagePlaceholder("1920x1080"),
  image: imagePlaceholder("400x600"),
  title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
  description:
    "<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>",
  app_store_link: "#",
  play_store_link: "#",
  containerSettings: {},
};

AppHeroWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["image-left", "Image Left"],
      ["image-right", "Image Right"],
    ],
  },
  background: { type: "custom", component: ImagePicker },
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  app_store_link: { type: "text" },
  play_store_link: { type: "text" },
};

export default AppHeroWidget;
