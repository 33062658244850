import React from "react";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

const LeadImageWidget = ({
  height,
  variant,
  overlay,
  image,
  logo,
  title,
  description,
  button_1,
  button_2,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div
      className="lead-image"
      style={{ backgroundImage: `url(${imageSrc(image, "original")})` }}
    >
      <div className="overlay" style={{ opacity: overlay }}></div>
      <div className="container">
        <div className="content-wrapper" style={{ height }}>
          <div className="content">
            {logo && <Img src={logo} variant="original" className="logo" />}

            {title && variant == "small" && (
              <h3 className="lead-image-title">{title}</h3>
            )}
            {title && variant == "large" && (
              <h1 className="lead-image-title">{title}</h1>
            )}

            {description != "<p><br></p>" && (
              <div className="row">
                <div className="col-8">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              </div>
            )}

            {(button_1.link && button_1.label) ||
            (button_2.link && button_2.label) ? (
              <div className="button-wrapper">
                {button_1.link && button_1.label && (
                  <Button settings={button_1} className="lg" />
                )}
                {button_2.link && button_2.label && (
                  <Button settings={button_2} className="lg" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.title = "Lead Image";
LeadImageWidget.category = "Media";
LeadImageWidget.dialogSize = "large";

LeadImageWidget.defaultProps = {
  height: "500px",
  description: "",
  button_1: {},
  button_2: {},
};

LeadImageWidget.defaults = {
  height: "500px",
  variant: "large",
  overlay: "0.2",
  image: "http://placehold.it/1920x1080",
  logo: "http://placehold.it/100x100",
  title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
  description:
    "<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>",
  button_1: {
    label: "Try now",
    link: "#",
    id: "",
    type: "primary",
    target: "_self",
    follow_link: "follow",
  },
  button_2: {
    label: "",
    link: "",
    id: "",
    type: "secondary",
    target: "_self",
    follow_link: "follow",
  },
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  height: { type: "text" },
  variant: {
    type: "select",
    options: [
      ["small", "Small"],
      ["large", "Large"],
    ],
  },
  overlay: {
    type: "select",
    options: [
      ["0.1", "10%"],
      ["0.2", "20%"],
      ["0.3", "30%"],
      ["0.4", "40%"],
      ["0.5", "50%"],
      ["0.6", "60%"],
      ["0.7", "70%"],
      ["0.8", "80%"],
      ["0.9", "90%"],
      ["1", "100%"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  logo: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button_1: { type: "button" },
  button_2: { type: "button" },
};

export default LeadImageWidget;
