import React from 'react';
import PropTypes from 'prop-types';

import LIBRARY from './widgets';
import Layout from './frontend/Layout';

const PageRender = ({ page, pageContext }) => (
  <Layout {...pageContext}>
    {page.widgets.map((widget, idx) =>
      React.createElement(
        LIBRARY[widget.component],
        Object.assign({}, { key: idx }, widget.settings)))}
  </Layout>
);

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
