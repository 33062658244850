import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';
import styled from 'styled-components';

const PlayerWarpper = styled.div`
  width: 100%;
  z-index: 3;
  background-color: #f5f5f5;
`;

const FocusMarker = styled.div`
  width: 1px;
  height: 60px;
  border: 0.5px solid #D33F51;
`;

const Word = styled.div`
  font-size: 32px;
  height: 80px;
  padding-top: 10px;
`;

const Letter = styled.span`
  padding: 0;
`;

const LetterFocused = styled.span`
  color: #D33F51;
  padding: 0;
`;

const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
};

const markerPosition = [
  { length: 3, position: 2 },
  { length: 4, position: 2 },
  { length: 5, position: 2 },
  { length: 6, position: 3 },
  { length: 7, position: 3 },
  { length: 8, position: 3 },
  { length: 9, position: 3 },
  { length: 10, position: 4 },
  { length: 11, position: 4 },
  { length: 12, position: 4 },
  { length: 13, position: 4 },
  { length: 14, position: 5 },
];

const getMarkerPosition = (wordLength) => {
  const wordPosition = markerPosition.find(({ length }) => length >= wordLength);

  return !wordPosition ? 5 : wordPosition.position;
};

const getFrameType = (word, wpm, pauses) => {
  const wordPause = (60 / wpm) * 1000;
  let wordIndex;

  const specialWords = {
    '[PARAGRAPH]': { render: false },
    '[COMMA]': { render: true },
    '[PERIOD]': { render: true },
  };

  if (word.endsWith(',')) { wordIndex = '[COMMA]'; }
  if (word.endsWith('.')) { wordIndex = '[PERIOD]'; }
  if (word === '') { wordIndex = '[PARAGRAPH]'; }

  const frameType = specialWords[wordIndex];

  if (frameType) {
    return {
      render: frameType.render,
      pause: wordPause + pauses[wordIndex],
      type: wordIndex,
    };
  }

  return {
    render: true,
    pause: wordPause,
    type: wordIndex,
  };
};

const WordWithFocus = ({ word, baseOffset }) => {
  const wordLength = word.length;
  const position = getMarkerPosition(wordLength);

  let startStr = '';
  let focusStr = '';
  let endStr = '';

  if (wordLength === 1) {
    focusStr = word;
  } else {
    startStr = word.slice(0, position - 1);
    focusStr = word.slice(position - 1, position);
    endStr = word.slice(position);
  }

  const font = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--base-font');

  const firstPartWidth = getTextWidth(startStr, `32px ${font}`);
  const focusPartWidth = getTextWidth(focusStr, `32px ${font}`);
  const endPartWidth = getTextWidth(endStr, `32px ${font}`);

  return (
    <Word
      style={{
        width: firstPartWidth + focusPartWidth + endPartWidth,
        marginLeft: baseOffset - (firstPartWidth + (focusPartWidth / 2)),
      }}
    >
      <Letter>{startStr}</Letter>
      <LetterFocused>
        {focusStr}
      </LetterFocused>
      <Letter>{endStr}</Letter>
    </Word>
  );
};

WordWithFocus.propTypes = {
  word: PropTypes.string.isRequired,
  baseOffset: PropTypes.number.isRequired,
};

const ToolbarBtn = ({ icon, onPress }) => (
  <a href="javascript:;" onClick={onPress}>
    <div className={icon}></div>
  </a>
);

ToolbarBtn.propTypes = {
  icon: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

const Toolbar = ({
  wpm,
  isPauseActive,
  onSpeedUp,
  onSlowDown,
  onPlayToggle,
  onReset,
  onGoBack,
  onGoForward,
  onToggleFullScreen,
}) => (
  <div className="toolbar">
    <div className="toolbar-left">
      <ToolbarBtn icon="reload-btn" onPress={onReset} />
      <ToolbarBtn icon="play-skip-back-btn" onPress={onGoBack} />
      <ToolbarBtn
        icon={isPauseActive ? 'play-btn' : 'pause-btn'}
        onPress={onPlayToggle}
      />
      <ToolbarBtn icon="play-skip-forward-btn" onPress={onGoForward} />
    </div>

    <div className="toolbar-right">
      <ToolbarBtn icon="minus-btn" onPress={onSlowDown} />
      <div className="current-wpm">{wpm}</div>
      <ToolbarBtn icon="plus-btn" onPress={onSpeedUp} />
      <ToolbarBtn icon="full-screen-btn" onPress={onToggleFullScreen} />
    </div>
  </div>
);

Toolbar.propTypes = {
  wpm: PropTypes.number.isRequired,
  isPauseActive: PropTypes.bool.isRequired,
  onSpeedUp: PropTypes.func.isRequired,
  onSlowDown: PropTypes.func.isRequired,
  onPlayToggle: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGoForward: PropTypes.func.isRequired,
  onToggleFullScreen: PropTypes.func.isRequired,
};

const Player = ({ words, defaultWpm, playerWidth }) => {
  const [playerState, setPlayerState] = useState({
    pause: true,
    currentPosition: 0,
    wpm: defaultWpm,
    fullScreen: false,
  });

  const baseOffset = playerWidth / 2;

  const managePlayerState = newValue => setPlayerState(prevState => ({ ...prevState, ...newValue }));

  const {
    currentPosition,
    wpm,
    pause,
    fullScreen,
  } = playerState;

  const wordsLength = words.length;

  const word = words[currentPosition];
  const paragraphPause = 700;
  const commaPause = 250;
  const periodPause = 450;

  const speedUp = () => managePlayerState({ wpm: wpm + 10 });
  const slowDown = () => managePlayerState({ wpm: wpm > 100 ? wpm - 10 : 100 });
  const goBack = () => managePlayerState({
    currentPosition: currentPosition !== 0 ? currentPosition - 1 : 0
  });
  const goForward = () => managePlayerState({
    currentPosition: currentPosition !== wordsLength - 1 ? currentPosition + 1 : wordsLength -1
  });
  const togglePlay = () => managePlayerState({ pause: !pause });
  const reset = () => managePlayerState({ currentPosition: 0 });
  const toggleFullScreen = () => managePlayerState({ fullScreen: !fullScreen });

  const frameType = getFrameType(
    word,
    wpm,
    {
      '[PARAGRAPH]': paragraphPause,
      '[COMMA]': commaPause,
      '[PERIOD]': periodPause,
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!pause && currentPosition < wordsLength - 1) {
        managePlayerState({
          currentPosition: currentPosition + 1,
          pause: currentPosition === wordsLength - 2,
        });
      }
    }, frameType.pause)

    return () => clearTimeout(timer);
  });

  return (
    <>
      <PlayerWarpper style={{ width: playerWidth, paddingTop: 32 }}>
        <FocusMarker style={{ marginLeft: baseOffset }} />

        <WordWithFocus
          word={word}
          baseOffset={baseOffset}
        />

        <FocusMarker style={{ marginLeft: baseOffset }}/>

        <Toolbar
          isPauseActive={pause}
          wpm={wpm}
          onSpeedUp={speedUp}
          onSlowDown={slowDown}
          onPlayToggle={togglePlay}
          onReset={reset}
          onGoBack={goBack}
          onGoForward={goForward}
          onToggleFullScreen={toggleFullScreen}
        />
      </PlayerWarpper>

      {fullScreen && <div className="player-overlay"></div>}
    </>
  );
};

Player.propTypes = {
  words: PropTypes.array.isRequired,
  defaultWpm: PropTypes.number.isRequired,
  playerWidth: PropTypes.number.isRequired,
};

const useResize = (myRef) => {
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    }

    window.addEventListener('resize', handleResize);
    document.addEventListener('DOMContentLoaded', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('DOMContentLoaded', handleResize);
    }
  }, [myRef])

  return { width, height }
}

const WordPlayerWidget = ({
  name,
  text,
  url,
  wpm,
  playerTitle,
  slug,
  articleImageUrl,
  containerSettings,
}) => {
  const playerWrapperRef = useRef();
  const [playerWidth, setPlayerWidth] = useState(0);

  const { width } = useResize(playerWrapperRef);

  useEffect(() => {
    setPlayerWidth(width || playerWrapperRef.current.offsetWidth)
  }, [width, playerWrapperRef]);

  return (
    <Container settings={containerSettings}>
      <div className="word-player">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img src={articleImageUrl} />
            </div>

            <div className="col-8">
              <div className="content">
                <h1>{name}</h1>
                <h5>Stream from</h5>
                <a href={url} target="blank">{url}</a>
              </div>
            </div>
          </div>

          <div className="row">
            <div ref={playerWrapperRef} className="col-12 content-wrapper">
              <div className="player-header">
                <h4>{playerTitle}</h4>
              </div>

              <Player
                playerWidth={playerWidth}
                defaultWpm={parseInt(wpm, 10)}
                words={text.split(/[\s]/)}
              />
            </div>

            <div
                className="fb-share-button"
                data-href={`https://spryreader.app/article/${slug}`}
                data-layout="button"
                data-size="large"
              >
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fspryreader.app%2F&amp;src=sdkpreparse"
                  className="fb-xfbml-parse-ignore">
                    Share
                </a>
              </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

WordPlayerWidget.title = 'Word Player';
WordPlayerWidget.category = 'Media';
WordPlayerWidget.dialogSize = 'large';

WordPlayerWidget.defaults = {
  containerSettings: {},
  playerTitle: 'Enjoiy speed reading with Spry Reader',
  articleImageUrl: 'http://placehold.it/400x300',
  name: '',
  url: '',
  text: 'Spry Reader is an e-reader application that dramatically increases the reading speed of digital text.',
  wpm: '200',
};

WordPlayerWidget.defaultProps = {
  name: 'Spry Reader',
  slug: 'example',
  playerTitle: 'Enjoiy speed reading with Spry Reader',
  articleImageUrl: 'http://placehold.it/400x300',
  url: 'http://spryreader.app',
  text: 'Spry Reader is an e-reader application that dramatically increases the reading speed of digital text.',
  wpm: '200',
};

WordPlayerWidget.propTypes = {
  text: PropTypes.string,
  slug: PropTypes.string,
  playerTitle: PropTypes.string,
  articleImageUrl: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  wpm: PropTypes.string,
  containerSettings: PropTypes.object.isRequired,
};

WordPlayerWidget.adminFields = {
  name: { type: 'text' },
  playerTitle: { type: 'text' },
  articleImageUrl: { type: 'text' },
  url: { type: 'text' },
  wpm: { type: 'text' },
  text: { type: 'textarea' },
};

export default WordPlayerWidget;
