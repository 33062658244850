import React from "react";
import PropTypes from "prop-types";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const ImageContained = ({ image, description }) => (
  <div className="container">
    <figure className="single-image">
      <Img src={image} variant="image" />
      <figcaption>{description}</figcaption>
    </figure>
  </div>
);

const ImageInset = ({ image, description }) => (
  <div className="container">
    <div className="grid">
      <figure className="single-image inset">
        <Img src={image} variant="image" />
        <figcaption>{description}</figcaption>
      </figure>
    </div>
  </div>
);

const SingleImageWidget = ({
  variant,
  image,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    {variant === "normal" ? (
      <ImageContained image={image} description={description} />
    ) : (
      <ImageInset image={image} description={description} />
    )}
  </Container>
);

SingleImageWidget.title = "Image";
SingleImageWidget.category = "Media";

SingleImageWidget.propTypes = {
  variant: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SingleImageWidget.defaults = {
  variant: "normal",
  image: "http://placehold.it/980x735",
  description: "Image description",
  containerSettings: {},
};

SingleImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["normal", "Normal"],
      ["inset", "Inset"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  description: { type: "text" },
};

export default SingleImageWidget;
