import React from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import { Container, List, TextInput, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

class LogoCarouselWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  render() {
    const { list, containerSettings } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="logos logo-carousel">
          <div className="container">
            <Swiper
              ref={(node) => {
                if (node) this.swiper = node.swiper;
              }}
              rebuildOnUpdate
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
              loop
              keyboard={true}
              paginationClickable
              slidesPerView={6}
              spaceBetween={30}
              speed={500}
              breakpoints={{
                939: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                  paginationClickable: true,
                  slidesPerGroup: 2,
                },
              }}
            >
              {list.map((item, idx) => (
                <div className="slide" key={idx}>
                  {item.link ? (
                    <a href={item.link} className="logo" target="_blank">
                      <Img src={item.image} variant="original" />
                    </a>
                  ) : (
                    <Img src={item.image} variant="original" className="logo" />
                  )}
                </div>
              ))}
            </Swiper>

            <button
              onClick={() => this.goNext()}
              className="swiper-button-next icon-arrow-right"
            />
            <button
              onClick={() => this.goPrev()}
              className="swiper-button-prev icon-arrow-left"
            />
          </div>
        </div>
      </Container>
    );
  }
}

LogoCarouselWidget.title = "Logos Carousel";
LogoCarouselWidget.category = "General";

LogoCarouselWidget.defaultProps = {
  list: [],
};

LogoCarouselWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

LogoCarouselWidget.defaults = {
  list: [
    { id: 1, image: "http://placehold.it/300x300", link: "" },
    { id: 2, image: "http://placehold.it/300x300", link: "" },
    { id: 3, image: "http://placehold.it/300x300", link: "" },
    { id: 4, image: "http://placehold.it/300x300", link: "" },
    { id: 5, image: "http://placehold.it/300x300", link: "" },
    { id: 6, image: "http://placehold.it/300x300", link: "" },
    { id: 7, image: "http://placehold.it/300x300", link: "" },
    { id: 8, image: "http://placehold.it/300x300", link: "" },
  ],
  variant: "3_columns",
  containerSettings: {},
};

const LogoItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

LogoItem.defaultProps = {
  settings: {},
};

LogoItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

LogoCarouselWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <LogoItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default LogoCarouselWidget;
