import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

class LeadImageSliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, height, autoplay, list } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="slider-wrapper">
          <div className="full-width">
            <div className="slider">
              <Swiper
                ref={(node) => {
                  if (node) this.swiper = node.swiper;
                }}
                rebuildOnUpdate
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
                loop
                keyboard={true}
                paginationClickable
                slidesPerView={1}
                speed={500}
                grabCursor={true}
                autoplay={
                  autoplay > 0
                    ? { delay: autoplay, disableOnInteraction: true }
                    : false
                }
              >
                {list.map((item) => (
                  <div className="slide" key={item.id}>
                    <div
                      className="lead-image"
                      style={{
                        backgroundImage: `url(${imageSrc(item.image, "lead")})`,
                      }}
                    >
                      <div
                        className="overlay"
                        style={{ opacity: item.overlay }}
                      ></div>
                      <div className="container">
                        <div className="content-wrapper" style={{ height }}>
                          <div className="content">
                            {item.title && item.variant == "small" && (
                              <h3>{item.title}</h3>
                            )}
                            {item.title && item.variant == "large" && (
                              <h1>{item.title}</h1>
                            )}

                            {item.description != "<p><br></p>" && (
                              <div className="row">
                                <div className="col-8">
                                  <div
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {(item.button_1.link && item.button_1.label) ||
                            (item.button_2.link && item.button_2.label) ? (
                              <div className="button-wrapper">
                                {item.button_1.link && item.button_1.label && (
                                  <Button
                                    settings={item.button_1}
                                    className="lg"
                                  />
                                )}
                                {item.button_2.link && item.button_2.label && (
                                  <Button
                                    settings={item.button_2}
                                    className="lg"
                                  />
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Swiper>

              <button
                onClick={() => this.goNext()}
                className="swiper-button-next icon-arrow-right"
              />
              <button
                onClick={() => this.goPrev()}
                className="swiper-button-prev icon-arrow-left"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

LeadImageSliderWidget.title = "Lead Image Slider";
LeadImageSliderWidget.category = "Media";
LeadImageSliderWidget.dialogSize = "large";

LeadImageSliderWidget.defaultProps = {
  list: [],
};

LeadImageSliderWidget.defaults = {
  list: [
    {
      id: 1,
      variant: "large",
      overlay: "0.2",
      image: "http://placehold.it/1920x1080",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
    {
      id: 2,
      variant: "large",
      overlay: "0.2",
      image: "http://placehold.it/1920x1080",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
    {
      id: 3,
      variant: "large",
      overlay: "0.2",
      image: "http://placehold.it/1920x1080",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
  ],
  height: "500px",
  autoplay: 0,
  containerSettings: {},
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <SelectMenu
      label="Vairant"
      options={[
        ["small", "Small"],
        ["large", "Large"],
      ]}
      value={settings.variant}
      onChange={(value) => onChangeItem("variant", value)}
    />

    <SelectMenu
      label="Overlay"
      options={[
        ["0.1", "10%"],
        ["0.2", "20%"],
        ["0.3", "30%"],
        ["0.4", "40%"],
        ["0.5", "50%"],
        ["0.6", "60%"],
        ["0.7", "70%"],
        ["0.8", "80%"],
        ["0.9", "90%"],
        ["1", "100%"],
      ]}
      value={settings.overlay}
      onChange={(value) => onChangeItem("overlay", value)}
    />

    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <ButtonSettings
      label="Button 1"
      onChange={(value) => onChangeItem("button_1", value)}
      value={settings.button_1}
    />

    <ButtonSettings
      label="Button 2"
      onChange={(value) => onChangeItem("button_2", value)}
      value={settings.button_2}
    />
  </div>
);

SlideItem.defaultProps = {
  settings: {},
};

LeadImageSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Height"
      onChange={(value) => onChange("height", value)}
      value={settings.height}
    />

    <TextInput
      label="Autoplay delay in ms (0 = disable)"
      onChange={(value) => onChange("autoplay", value)}
      value={settings.autoplay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default LeadImageSliderWidget;
