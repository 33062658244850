import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
  Link,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Speaker = ({ title, link, image, description }) => (
  <div className="speaker">
    {link ? (
      <Link settings={link} className="avatar">
        <Img src={image} variant="square" />
      </Link>
    ) : (
      <div className="avatar">
        <Img src={image} variant="square" />
      </div>
    )}

    <div className="speaker-content">
      <h6 className="speaker-name">
        {link && link.link ? <Link settings={link}>{title}</Link> : title}
      </h6>
      {description && (
        <div
          className="speaker-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  </div>
);

const cardsVariant = {
  2: 6,
  3: 4,
  4: 3,
  6: 2,
};

const SpeakersWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="speakers">
      <div className="container">
        <div className="row">
          {list.map((card) => (
            <div key={card.id} className={`col-${cardsVariant[variant]}`}>
              <Speaker
                title={card.title}
                link={card.link}
                image={card.image}
                description={card.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

SpeakersWidget.title = "Speakers";
SpeakersWidget.category = "Media";
SpeakersWidget.dialogSize = "large";

SpeakersWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "John Doe",
      link: {},
      description: "Developer",
      image: "http://placehold.it/400x400",
    },
    {
      id: 2,
      title: "John Doe",
      link: {},
      description: "Developer",
      image: "http://placehold.it/400x400",
    },
    {
      id: 3,
      title: "John Doe",
      link: {},
      description: "Developer",
      image: "http://placehold.it/400x400",
    },
  ],
  containerSettings: {},
};

const SpeakerListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

SpeakersWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
        [6, 6],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <SpeakerListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default SpeakersWidget;
