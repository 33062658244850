import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TextAreaInput = ({
  namespace,
  name,
  label,
  value,
  onChange,
}) => (
  <div className={`form-group string ${namespace}_${name}`}>
    <label
      className="control-label"
      htmlFor={`${namespace}_${name}`}
    >{label}</label>

    <textarea
      className="form-control text"
      rows="20"
      name={`${namespace}[${name}]`}
      id={`${namespace}_${name}`}
      onChange={e => onChange(e.target.value)}
      value={value}
    />
  </div>
);

TextAreaInput.propTypes = {
  namespace: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextAreaInput;
