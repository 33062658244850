import React from 'react';
import PropTypes from 'prop-types';

const HiddenInput = ({
  namespace,
  name,
  value,
}) => (
  <input
    value={value}
    type="hidden"
    name={`${namespace}[${name}]`}
    id={`${namespace}_${name}`}
  />
);

HiddenInput.propTypes = {
  namespace: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default HiddenInput;
