import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
  EmbeddedVideo,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Modal from "../frontend/Modal";

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { title, video_url, thumbnail } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="video-thumb">
        <div className="click-target" onClick={this.handleOpen}>
          <Img src={thumbnail} variant="card_horizontal" />

          <div className="overlay" />
          <div className="play-icon" />
          <h4>{title}</h4>
        </div>

        <Modal isOpen={isOpen} onClose={this.handleClose}>
          {isOpen && (
            <div className="video-wrapper">
              <EmbeddedVideo videoUrl={video_url} />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

const cardsVariant = {
  3: 4,
  4: 3,
};

const VideoGallery = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="video-gallery">
      <div className="container">
        <div className="row">
          {list.map((card, idx) => (
            <div key={card.id} className={`col-${cardsVariant[variant]}`}>
              <Video
                title={card.title}
                video_url={card.video_url}
                thumbnail={card.thumbnail}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

VideoGallery.title = "Video Gallery";
VideoGallery.category = "Media";
VideoGallery.dialogSize = "large";

VideoGallery.propTypes = {
  variant: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

VideoGallery.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "Video title",
      video_url: "https://www.youtube.com/watch?v=ZfytHvgHybA",
      thumbnail: "http://placehold.it/400x300",
    },
    {
      id: 2,
      title: "Video title",
      video_url: "https://www.youtube.com/watch?v=ZfytHvgHybA",
      thumbnail: "http://placehold.it/400x300",
    },
    {
      id: 3,
      title: "Video title",
      video_url: "https://www.youtube.com/watch?v=ZfytHvgHybA",
      thumbnail: "http://placehold.it/400x300",
    },
  ],
  containerSettings: {},
};

const VideoGalleryItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Thumbnail"
      onChange={(value) => onChangeItem("thumbnail", value)}
      value={settings.thumbnail}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Video link (YouTube/Vimeo)"
      onChange={(value) => onChangeItem("video_url", value)}
      value={settings.video_url}
    />
  </div>
);

VideoGalleryItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

VideoGallery.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <VideoGalleryItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default VideoGallery;
