import React from "react";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const AndroidBetaHeroWidget = ({
  image,
  title,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="android-beta-hero">
      <div className="container">
        <div className="row">
          <div className="col-6 content-wrapper">
            <div className="header-info">
              <h1>{title}</h1>
              {description != "<p><br></p>" && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              <div className="android-icon" /> Coming soon
            </div>
          </div>

          <div className="col-6">
            <div className="image-wrapper">
              <Img src={image} variant="original" className="header-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

AndroidBetaHeroWidget.title = "Android Beta Hero";
AndroidBetaHeroWidget.category = "Media";
AndroidBetaHeroWidget.dialogSize = "large";

AndroidBetaHeroWidget.defaults = {
  image: "http://placehold.it/908x486",
  title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
  description:
    "<p>Ad distinctio modi, quo provident laudantium debitis autem repudiandae iusto a nesciunt. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>",
  containerSettings: {},
};

AndroidBetaHeroWidget.adminFields = {
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "text" },
};

export default AndroidBetaHeroWidget;
