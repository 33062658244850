import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

class FaqWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openIdx: -1,
    };
  }

  render() {
    const { list, containerSettings } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="faq">
          <div className="container">
            {list.map((item) => (
              <div className="faq-item" key={item.id}>
                <div className="faq-item-title">
                  <h4>{item.title}</h4>
                </div>

                <div className="faq-item-content">
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    );
  }
}

FaqWidget.defaultProps = {
  icons: "yes",
};

FaqWidget.title = "FAQ";
FaqWidget.category = "Media";
FaqWidget.dialogSize = "large";

FaqWidget.propTypes = {
  icons: PropTypes.string,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

FaqWidget.defaults = {
  icons: "yes",
  list: [
    {
      id: 1,
      title: "Question?",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      title: "Question?",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 3,
      title: "Question?",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const FaqsListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

FaqsListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

FaqWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Icons"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.icons}
      onChange={(value) => onChange("icons", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <FaqsListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default FaqWidget;
