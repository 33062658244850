import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  Img,
  TextInput,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

const Slide = ({ image, icon, title, description, button_1, button_2 }) => (
  <div className="spotlight-slide">
    <div className="container">
      <div className="row">
        <div className="col-6">
          <div className="image-wrapper">
            <Img src={image} variant="original" />
          </div>
        </div>

        <div className="col-6">
          <div className="content-wrapper">
            <div>
              {icon && (
                <div className="icon">
                  <Img src={icon} variant="original" />
                </div>
              )}

              <h3>{title}</h3>

              {description != "<p><br></p>" && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}

              {(button_1.link && button_1.label) ||
              (button_2.link && button_2.label) ? (
                <div className="button-wrapper">
                  {button_1.link && button_1.label && (
                    <Button settings={button_1} className="lg" />
                  )}
                  {button_2.link && button_2.label && (
                    <Button settings={button_2} className="lg" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class AppSpotlightSliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, autoplay, list } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="slider-wrapper app-spotlight-slider">
          <div className="slider">
            <Swiper
              ref={(node) => {
                if (node) this.swiper = node.swiper;
              }}
              rebuildOnUpdate
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
              loop
              keyboard={true}
              paginationClickable
              slidesPerView={1}
              speed={500}
              grabCursor={true}
              autoplay={
                autoplay > 0
                  ? { delay: autoplay, disableOnInteraction: true }
                  : false
              }
            >
              {list.map((item) => (
                <div className="slide" key={item.id}>
                  <Slide {...item} />
                </div>
              ))}
            </Swiper>

            <button
              onClick={() => this.goNext()}
              className="swiper-button-next icon-arrow-right"
            />
            <button
              onClick={() => this.goPrev()}
              className="swiper-button-prev icon-arrow-left"
            />
          </div>
        </div>
      </Container>
    );
  }
}

AppSpotlightSliderWidget.title = "App Spotlight Slider";
AppSpotlightSliderWidget.category = "Media";
AppSpotlightSliderWidget.dialogSize = "large";

AppSpotlightSliderWidget.defaultProps = {
  list: [],
};

AppSpotlightSliderWidget.defaults = {
  list: [
    {
      id: 1,
      icon: "https://placehold.it/64x64",
      image: "http://placehold.it/400x600",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
    {
      id: 2,
      icon: "https://placehold.it/64x64",
      image: "http://placehold.it/400x600",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
    {
      id: 3,
      icon: "https://placehold.it/64x64",
      image: "http://placehold.it/400x600",
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, voluptate nam tempore qui accusamus, ratione quia.</p>",
      button_1: {},
      button_2: {},
    },
  ],
  autoplay: 0,
  containerSettings: {},
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
    <ImagePicker
      label="icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <ButtonSettings
      label="Button 1"
      onChange={(value) => onChangeItem("button_1", value)}
      value={settings.button_1}
    />

    <ButtonSettings
      label="Button 2"
      onChange={(value) => onChangeItem("button_2", value)}
      value={settings.button_2}
    />
  </div>
);

SlideItem.defaultProps = {
  settings: {},
};

AppSpotlightSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Autoplay delay in ms (0 = disable)"
      onChange={(value) => onChange("autoplay", value)}
      value={settings.autoplay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default AppSpotlightSliderWidget;
