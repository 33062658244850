import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

const IntroParagraphWidget = ({
  text,
  button_1,
  button_2,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <div className="col-8">
          <div className="intro">
            <div dangerouslySetInnerHTML={{ __html: text }} />

            {(button_1.link && button_1.label) ||
            (button_2.link && button_2.label) ? (
              <div className="button-wrapper">
                {button_1.link && button_1.label && (
                  <Button settings={button_1} />
                )}
                {button_2.link && button_2.label && (
                  <Button settings={button_2} />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

IntroParagraphWidget.title = "Intro Paragraph";
IntroParagraphWidget.category = "Media";

IntroParagraphWidget.defaultProps = {
  button_1: {},
  button_2: {},
};

IntroParagraphWidget.propTypes = {
  text: PropTypes.string,
  button_1: PropTypes.object,
  button_2: PropTypes.object,
  containerSettings: PropTypes.object.isRequired,
};

IntroParagraphWidget.defaults = {
  text:
    "<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!</p>",
  button_1: {
    label: "Read the case study",
    link: "#",
    id: "",
    type: "secondary",
    target: "_self",
    follow_link: "follow",
  },
  button_2: {
    label: "Download",
    link: "#",
    id: "",
    type: "text",
    target: "_self",
    follow_link: "follow",
  },
  containerSettings: {},
};

IntroParagraphWidget.adminFields = {
  text: { type: "custom", component: RichText },
  button_1: { type: "button" },
  button_2: { type: "button" },
};

export default IntroParagraphWidget;
