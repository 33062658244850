import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const SectionTitleWidget = ({ variant, title, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="section-title">
      <div className="container">
        {variant === 'large' && <h1>{title}</h1>}
        {variant === 'medium' && <h2>{title}</h2>}
        {variant === 'small' && <h3>{title}</h3>}
      </div>
    </div>
  </Container>
);

SectionTitleWidget.title = 'Section Title';
SectionTitleWidget.category = 'General';
SectionTitleWidget.primaryField = 'title';

SectionTitleWidget.defaultProps = {
  variant: 'medium',
};

SectionTitleWidget.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SectionTitleWidget.defaults = {
  variant: 'medium',
  title: 'Section title',
  containerSettings: {},
};

SectionTitleWidget.adminFields = {
  variant: { type: 'select', options: [['small', 'Small'], ['medium', 'Medium'], ['large', 'Large']] },
  title: { type: 'text' },
};

export default SectionTitleWidget;
