import React from "react";
import Swiper from "react-id-swiper";
import {
  Container,
  List,
  Img,
  TextInput,
  SelectMenu,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

class TestimonialSliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, autoplay, list } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="slider-wrapper">
          <div className="container">
            <div className="slider">
              <Swiper
                ref={(node) => {
                  if (node) this.swiper = node.swiper;
                }}
                rebuildOnUpdate
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
                loop
                keyboard={true}
                paginationClickable
                slidesPerView={1}
                speed={500}
                grabCursor={true}
                autoplay={
                  autoplay > 0
                    ? { delay: autoplay, disableOnInteraction: true }
                    : false
                }
              >
                {list.map((item, idx) => (
                  <div className="slide" key={idx}>
                    <div className="row">
                      <div className="shift-3">
                        <figure className="testimonial">
                          <blockquote
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />

                          <figcaption>
                            <Img
                              src={item.avatar}
                              variant="square"
                              className="avatar"
                            />
                            {item.author}
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                ))}
              </Swiper>

              <button
                onClick={() => this.goNext()}
                className="swiper-button-next icon-arrow-right"
              />
              <button
                onClick={() => this.goPrev()}
                className="swiper-button-prev icon-arrow-left"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

TestimonialSliderWidget.title = "Testimonial Slider";
TestimonialSliderWidget.category = "General";

TestimonialSliderWidget.defaults = {
  list: [
    {
      id: 1,
      avatar: "http://placehold.it/540",
      text:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, incidunt deleniti veniam facere porro numquam temporibus molestiae dicta facilis aliquam praesentium minus fugiat libero labore alias recusandae sint, quaerat veritatis.</p>",
      author: "John Doe",
    },
    {
      id: 2,
      avatar: "http://placehold.it/540",
      text:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, incidunt deleniti veniam facere porro numquam temporibus molestiae dicta facilis aliquam praesentium minus fugiat libero labore alias recusandae sint, quaerat veritatis.</p>",
      author: "John Doe",
    },
    {
      id: 3,
      avatar: "http://placehold.it/540",
      text:
        "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, incidunt deleniti veniam facere porro numquam temporibus molestiae dicta facilis aliquam praesentium minus fugiat libero labore alias recusandae sint, quaerat veritatis.</p>",
      author: "John Doe",
    },
  ],
  autoplay: 0,
  containerSettings: {},
};

const TestimonialItem = ({ settings, onChangeItem }) => (
  <div>
    <RichText
      label="Text"
      onChange={(value) => onChangeItem("text", value)}
      value={settings.text}
    />

    <TextInput
      label="Author"
      onChange={(value) => onChangeItem("author", value)}
      value={settings.author}
    />

    <ImagePicker
      label="Avatar"
      onChange={(value) => onChangeItem("avatar", value)}
      value={settings.avatar}
    />
  </div>
);

TestimonialSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Autoplay delay in ms (0 = disable)"
      onChange={(value) => onChange("autoplay", value)}
      value={settings.autoplay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <TestimonialItem settings={settings} onChangeItem={onChangeItem} />
      )}
      template={{
        avatar: "http://placehold.it/540",
        text:
          "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, incidunt deleniti veniam facere porro numquam temporibus molestiae dicta facilis aliquam praesentium minus fugiat libero labore alias recusandae sint, quaerat veritatis.</p>",
        author: "John Doe",
      }}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default TestimonialSliderWidget;
