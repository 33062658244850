import React from 'react';
import Link from './Link';

const SiteHeader = ({ headerMenu }) => (
  <div className="site-header">
    <div className="container">
      <div className="site-header-inner">
        <h1>
          <a href="/" className="logo">SpryReader</a>
        </h1>

        <nav>
          {headerMenu.items.map((item, idx) =>
            <Link key={idx} {...item.link} />
          )}
        </nav>
      </div>
    </div>
  </div>
);

export default SiteHeader;
