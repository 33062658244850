import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const FeaturesWidget = ({ list, icons, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="features">
      <div className="container">
        <div className="row">
          {list.map((item, idx) => (
            <div className="col-4" key={idx}>
              <div className="feature-card">
                {icons === "yes" ? (
                  <div className="icon">
                    <Img src={item.icon} variant="original" />
                  </div>
                ) : null}

                <div className="content">
                  <h4>{item.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

FeaturesWidget.title = "Features";
FeaturesWidget.category = "Media";
FeaturesWidget.dialogSize = "large";

FeaturesWidget.propTypes = {
  icons: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

FeaturesWidget.defaults = {
  icons: "no",
  list: [
    {
      id: 1,
      title: "Feature name",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: imagePlaceholder("64x64"),
    },
    {
      id: 2,
      title: "Feature name",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: imagePlaceholder("64x64"),
    },
    {
      id: 3,
      title: "Feature name",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
      icon: imagePlaceholder("64x64"),
    },
  ],
  containerSettings: {},
};

const FeaturesListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

FeaturesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Icons"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.icons}
      onChange={(value) => onChange("icons", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <FeaturesListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      template={{
        title: "Feature name",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.",
        icon: imagePlaceholder("64x64"),
      }}
      primaryField="title"
    />
  </div>
);

export default FeaturesWidget;
