import SectionTitleWidget from './SectionTitleWidget';
import SingleImageWidget from './SingleImageWidget';
import TextBoxWidget from './TextBoxWidget';
import NavigationWidget from './NavigationWidget';
import HtmlWidget from './HtmlWidget';
import CardsWidget from './CardsWidget';
import CtaWidget from './CtaWidget';
import IntroParagraphWidget from './IntroParagraphWidget';
import LeadImageWidget from './LeadImageWidget';
import LogoCarouselWidget from './LogoCarouselWidget';
import LogosWidget from './LogosWidget';
import StatsWidget from './StatsWidget';
import VideoWidget from './VideoWidget';
import FeaturesWidget from './FeaturesWidget';
import TextSpotlightWidget from './TextSpotlightWidget';
import FaqWidget from './FaqWidget';
import GallerySliderWidget from './GallerySliderWidget';
import LeadImageSliderWidget from './LeadImageSliderWidget';
import SliderWidget from './SliderWidget';
import TableWidget from './TableWidget';
import SpeakersWidget from './SpeakersWidget';
import VideoGalleryWidget from './VideoGalleryWidget';
import MediaList from './MediaList';
import SpotlightWidget from './SpotlightWidget';
import AppHeroWidget from './AppHeroWidget';
import AndroidBetaHeroWidget from './AndroidBetaHeroWidget';
import AppSpotlightWidget from './AppSpotlightWidget';
import AppSpotlightSliderWidget from './AppSpotlightSliderWidget';
import WordPlayerWidget from './WordPlayerWidget';
import SocialWidget from './SocialWidget';
import TestimonialSliderWidget from './TestimonialSliderWidget';

  export default {
    SectionTitleWidget,
  SingleImageWidget,
  TextBoxWidget,
  NavigationWidget,
  HtmlWidget,
  CardsWidget,
  CtaWidget,
  IntroParagraphWidget,
  LeadImageWidget,
  LogoCarouselWidget,
  LogosWidget,
  StatsWidget,
  VideoWidget,
  FeaturesWidget,
  TextSpotlightWidget,
  FaqWidget,
  GallerySliderWidget,
  LeadImageSliderWidget,
  SliderWidget,
  TableWidget,
  SpeakersWidget,
  VideoGalleryWidget,
  MediaList,
  SpotlightWidget,
  AppHeroWidget,
  AndroidBetaHeroWidget,
  AppSpotlightWidget,
  AppSpotlightSliderWidget,
  WordPlayerWidget,
  SocialWidget,
  TestimonialSliderWidget,
  };
  