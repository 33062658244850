import React from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import Lightbox from "react-images";
import { Container, List, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";

class GallerySliderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
      lighboxOpen: false,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, list, initialSlide } = this.props;
    const { currentImageIdx, lighboxOpen } = this.state;

    const galleryImages = list.map((item) => {
      return { src: imageSrc(item.image, "lead") };
    });

    return (
      <Container settings={containerSettings}>
        <div className="gallery-slider-wrapper">
          <div className="container">
            <div className="gallery-slider">
              <Lightbox
                images={galleryImages}
                isOpen={lighboxOpen}
                currentImage={currentImageIdx}
                enableKeyboardInput={true}
                showCloseButton={true}
                backdropClosesModal={true}
                onClickPrev={() => this.handlePrevImage()}
                onClickNext={() => this.handleNextImage()}
                onClose={() => this.setState({ lighboxOpen: false })}
              />

              <Swiper
                ref={(node) => {
                  if (node) this.swiper = node.swiper;
                }}
                rebuildOnUpdate
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
                loop
                keyboard={true}
                paginationClickable
                slidesPerView={4}
                spaceBetween={30}
                speed={500}
                breakpoints={{
                  939: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    paginationClickable: true,
                    slidesPerGroup: 2,
                  },
                  600: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    slidesPerGroup: 1,
                    slidesPerColumn: 1,
                    paginationClickable: true,
                  },
                }}
              >
                {list.map((item, idx) => (
                  <div
                    className="slide"
                    key={idx}
                    onClick={() =>
                      this.setState({ currentImageIdx: idx, lighboxOpen: true })
                    }
                  >
                    <Img
                      src={item.image}
                      variant="card_horizontal"
                      className="slider-image"
                    />
                  </div>
                ))}
              </Swiper>

              <button
                onClick={() => this.goNext()}
                className="swiper-button-next icon-arrow-right"
              />
              <button
                onClick={() => this.goPrev()}
                className="swiper-button-prev icon-arrow-left"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

GallerySliderWidget.title = "Gallery Slider";
GallerySliderWidget.category = "Media";
GallerySliderWidget.dialogSize = "large";

GallerySliderWidget.defaultProps = {
  list: [],
};

GallerySliderWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

GallerySliderWidget.defaults = {
  list: [
    { id: 1, image: "http://placehold.it/800x600" },
    { id: 2, image: "http://placehold.it/800x600" },
    { id: 3, image: "http://placehold.it/800x600" },
    { id: 4, image: "http://placehold.it/800x600" },
    { id: 5, image: "http://placehold.it/800x600" },
  ],
  variant: "3_columns",
  containerSettings: {},
};

const GalleryItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

GalleryItem.defaultProps = {
  settings: {},
};

GalleryItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

GallerySliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <GalleryItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default GallerySliderWidget;
