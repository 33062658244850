import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const SocialWidget = ({
  title,
  facebookUrl,
  instagramUrl,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="social">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{title}</h3>

            <div className="content-wrapper">
              <a href={instagramUrl} target="blank">
                <div className="instagram"></div>
              </a>

              <a href={facebookUrl} target="blank">
                <div className="facebook"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

SocialWidget.title = 'Social';
SocialWidget.category = 'Media';

SocialWidget.propTypes = {
  title: PropTypes.string.isRequired,
  instagramUrl: PropTypes.string.isRequired,
  facebookUrl: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SocialWidget.defaults = {
  title: 'Follow us',
  instagramUrl: 'https://www.instagram.com/spryreader/',
  facebookUrl: 'https://www.facebook.com/SpryReader/',
  containerSettings: {},
};

SocialWidget.adminFields = {
  title: { type: 'text' },
  facebookUrl: { type: 'text' },
  instagramUrl: { type: 'text' },
};

export default SocialWidget;
