import React from "react";
import PropTypes from "prop-types";
import { Container, List, TextInput, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const LogosWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="logos">
      <div className="container">
        <div className="row">
          {list.map((item, idx) => (
            <div className="col-2" key={idx}>
              {item.link ? (
                <a href={item.link} className="logo" target="_blank">
                  <Img src={item.image} variant="original" />
                </a>
              ) : (
                <Img src={item.image} variant="original" className="logo" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

LogosWidget.title = "Logos";
LogosWidget.category = "General";

LogosWidget.defaultProps = {
  list: [],
};

LogosWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

LogosWidget.defaults = {
  list: [
    { id: 1, image: "http://placehold.it/300x300", link: "" },
    { id: 2, image: "http://placehold.it/300x300", link: "" },
    { id: 3, image: "http://placehold.it/300x300", link: "" },
    { id: 4, image: "http://placehold.it/300x300", link: "" },
    { id: 5, image: "http://placehold.it/300x300", link: "" },
  ],
  variant: "3_columns",
  containerSettings: {},
};

const LogoItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

LogoItem.defaultProps = {
  settings: {},
};

LogoItem.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

LogosWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <LogoItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default LogosWidget;
