import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";

const TextSpotlightWidget = ({
  title,
  description,
  button_1,
  button_2,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="text-spotlight">
      <div className="container">
        <div className="row">
          <div className="col-6">{title && <h2>{title}</h2>}</div>
          <div className="col-6">
            {description != "<p><br></p>" && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}

            {(button_1.link && button_1.label) ||
            (button_2.link && button_2.label) ? (
              <div className="button-wrapper">
                {button_1.link && button_1.label && (
                  <Button settings={button_1} className="lg" />
                )}
                {button_2.link && button_2.label && (
                  <Button settings={button_2} className="lg" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

TextSpotlightWidget.title = "Text Spotlight";
TextSpotlightWidget.category = "Media";

TextSpotlightWidget.defaultProps = {
  description: "",
  button_1: {},
  button_2: {},
};

TextSpotlightWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  button_1: PropTypes.object,
  button_2: PropTypes.object,
  containerSettings: PropTypes.object.isRequired,
};

TextSpotlightWidget.defaults = {
  title: "Spotlight",
  description:
    "<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique, itaque reiciendis quidem sequi beatae enim rem officia illo totam quod incidunt est ut blanditiis ab nostrum? Obcaecati, consequatur facilis! Porro!</p>",
  button_1: {
    label: "Read the case study",
    link: "#",
    id: "",
    type: "secondary",
    target: "_self",
    follow_link: "follow",
  },
  button_2: {
    label: "",
    link: "",
    id: "",
    type: "text",
    target: "_self",
    follow_link: "follow",
  },
  containerSettings: {},
};

TextSpotlightWidget.adminFields = {
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button_1: { type: "button" },
  button_2: { type: "button" },
};

export default TextSpotlightWidget;
